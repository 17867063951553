<template>
  <a-spin :spinning="spinning">
    <div :class="{disabled: disabled}">
      <a-card class="card" title="业务信息" :bordered="false">
        <div slot="extra">
          <a-affix :offsetTop="50" :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right'}">
            <a-button><router-link :to="{ name: 'CumsExport'}">取消</router-link></a-button>
            <a-divider type="vertical" />
            <a-button :disabled="!isEdit" type="primary" @click="handleExport">导出</a-button>
            <a-divider type="vertical" />
            <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
          </a-affix>
        </div>
        <a-form-model layout="vertical" :model="form"
                      :rules="rules" ref="form">
          <a-row :gutter="20">
            <a-col :span="6">
              <a-form-model-item label="业务编号">
                <a-input v-model="form.serial_num" disabled placeholder="系统自动生成"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="清关系统业务编号">
                <a-input v-model="form.clear_sys_serial_num" @change="queryClearDetail" allowClear/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="清关编号">
                <a-input v-model="form.clear_serial_num"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="委托客户" prop="auth_agency" :autoLink="false">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  v-model="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="客户编号">
                <a-input v-model="form.customer_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="品名">
                <a-input v-model="form.product_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="总体积">
                <a-input-number v-model="form.total_volume" style="width: 372px" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="6">
              <a-form-model-item label="船名航次">
                <a-input v-model="form.ship_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="提单号">
                <a-input v-model="form.delivery_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="港口">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择港口"
                  style="width: 100%"
                  v-model="form.port_id"
                >
                  <a-select-option v-for="op in portOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col> -->
            <!-- <a-col :span="6">
              <a-form-model-item label="开航日期">
                <a-date-picker v-model="form.sail_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col> -->
            <a-col :span="6">
              <a-form-model-item label="出库日期">
                <a-date-picker v-model="form.export_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="查验日期">
                <a-date-picker v-model="form.check_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="客服员">
                <!-- <a-input disabled v-model="form.customer"/> -->
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择客服员"
                  style="width: 100%"
                  v-model="form.customer"
                >
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="运输车队">
                <a-input v-model="form.trans_comp" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="6">
              <a-form-model-item label="贸易方式">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择贸易方式"
                  style="width: 100%"
                  v-model="form.trade_type"
                >
                  <a-select-option v-for="op in tradeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="海关编号">
                <a-input v-model="form.customs_num" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="6">
              <a-form-model-item label="送货日期">
                <a-date-picker v-model="form.send_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="派单日期">
                <a-date-picker v-model="form.dispatch_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="箱型箱号箱量">
                <template v-for="(box, index) in form.box_data">
                  <a-input-group compact :key="index" class="field-item box-item">
                    <a-select
                      showSearch :filter-option="filterOption"
                      placeholder="箱型"
                      style="width: 90px"
                      v-model="box.id"
                      :allowClear="true">
                      <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                    </a-select>
                    <a-input v-model="box.box_num" style="width: 120px" placeholder="箱号"/>
                    <a-input-number v-model="box.num" style="width: 100px" placeholder="箱量"/>
                    <a-icon type="close-circle" theme="filled"
                            class="field-item_del" v-if="index > 0"
                            @click="delItem('box_data', index)"/>
                    <a-button type="primary" v-if="index === (form.box_data.length - 1)"
                              style="width: 60px;margin-left: 10px" @click="createItem('box_data')">更多</a-button>
                  </a-input-group>
                </template>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="6">
              <a-form-model-item label="卫生证书编号">
                <a-input v-model="form.health_cert" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="出口核注清单号">
                <a-input v-model="form.clear_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="客户出库单号">
                <a-input v-model="form.export_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="3">
              <a-form-model-item label="本仓操作">
                <a-switch checked-children="是" un-checked-children="否" v-model="form.local"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="3">
              <a-form-model-item label="奥吉报关">
                <a-switch checked-children="是" un-checked-children="否" v-model="form.ao_ji"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-model-item label="出库操作备注">
                <a-textarea v-model="form.operate_remark"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="12">
              <a-form-model-item label="备注">
                <a-textarea v-model="form.remark"></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="反馈备注">
                <a-textarea v-model="form.feed_back" disabled></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
      <a-card class="card" title="其它信息" :bordered="false">
        <a-tabs :activeKey="activeKey" @change="key => {activeKey = key}">
          <a-tab-pane tab="货物信息" key="1">
            <edit-table
              scroll
              :operate="{ add: true, del: true, alldel: true }"
              :columns="goodsColumns" :sourceData="goodsData"
              @getTableDate="getGoodsData"></edit-table>
          </a-tab-pane>
          <a-tab-pane tab="费用信息" key="2" forceRender>
            <a-tabs default-active-key="a">
              <a-tab-pane key="a" tab="应收费用">
                <edit-table
                  scroll
                  :columns="chargeFeeColumns"
                  :sourceData="chargeFeeData"
                  :feeStatusFlag="true"
                  @getTableDate="getChargeFeeData"
                  @getTableDateError="getTableDateError"></edit-table>
              </a-tab-pane>
              <a-tab-pane key="b" tab="应付费用">
                <edit-table
                  scroll
                  ref="order_pay_fee"
                  :columns="payFeeColumns"
                  :sourceData="payFeeData"
                  :feeStatusFlag="true"
                  @getTableDate="getPayFeeData"
                  @getTableDateError="getTableDateError"></edit-table>
              </a-tab-pane>
            </a-tabs>
          </a-tab-pane>
          <template slot="tabBarExtraContent">
            <template v-if="activeKey === '2'">
              <span style="margin-right: 20px">应收：{{ totalChargeFee.toFixed(2) }} </span>
              <span style="margin-right: 20px"> 应付：{{ totalPayFee.toFixed(2) }} </span>
              <span style="margin-right: 100px"> 利润：{{ (totalChargeFee - totalPayFee).toFixed(2) }} </span>
              <!-- <a-button type="primary" :disabled="!isEdit" @click="getPayQrcode">
                现金收费
              </a-button> -->
            </template>
          </template>
        </a-tabs>
      </a-card>
      <pay-qrcode :visible.sync="payQrcodeVisible" v-if="getQrcodeParams.id"
                  :getQrcodeFun="getCumsPayQrcode" :params="getQrcodeParams"></pay-qrcode>
    </div>
  </a-spin>
</template>

<script>
// import Vue from 'vue'
import { downloadExcel, getCommonOptions, getCustomer, getSupplier } from '@/api/common'
import { getPayTargetList } from '@/api/wms/sale'
import { saveCumsBusinessInfo, getCumsBusinsessDetail, getListClearGoods, getCumsMetaOption, getNumOptions, getCumsPayQrcode, exportCmsLetter, detailBySerialNum } from '@/api/cums'
import { EditTable } from '@/components'
import { filterOption } from '@/utils/util'
import moment from 'moment';
import PayQrcode from '@/views/components/PayQrcode'
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce';
export default {
  components: {
    EditTable, PayQrcode, preventReClick
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      form: {},
      spinning: false,
      customerInfo: undefined,
      fetching: false,
      sourceForm: { // 2020.11.03 出库编辑界面删除字段：开航日期、船名航次、提单号、港口、贸易方式、海关编号
        id: null,
        serial_num: '',
        clear_serial_num: '',
        auth_agency: null,
        customer_num: '',
        product_name: '',
        // ship_name: '',
        // delivery_num: '',
        // port_id: null,
        // sail_date: null,
        export_date: null, // 出库日期
        check_date: null,
        // customer: Vue.ls.get('LOGIN_NAME'),
        customer: null,
        trans_comp: '',
        // trade_type: null,
        // customs_num: '',
        box_data: [{ id: null, num: null, box_num: '' }],
        send_date: null,
        dispatch_date: null,
        local: true,
        ao_ji: true,
        health_cert: '',
        clear_num: '',
        export_num: '',
        remark: '',
        feed_back: '',
        operate_remark: ''
      },
      isEdit: false,
      disabled: false,
      activeKey: '1',
      boxOps: [],
      agencyOps: [],
      portOps: [],
      tradeOps: [],
      customerOps: [],
      // customerContactOps: [],
      shipComOps: [],
      goodsData: [],
      feeData: [],
      rules: {
        auth_agency: [
          { required: true, message: '请选择委托客户', trigger: 'blur' }
        ]
      },
      goodsColumns: [
        {
          title: '出库核注清单号',
          dataIndex: 'check_num',
          width: 200,
          extra: {
            type: 'select',
            // func: getNumOptions,
            // params: [],
            options: [],
            extraFunc: this.checkNumChange,
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 120,
          extra: {
            events: {
              'pressEnter': this.goodNameChange.bind(this, 'good_name')
            },
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '商品规格型号',
          dataIndex: 'good_spec',
          width: 120,
          extra: {
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100,
          extra: {
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 80,
          extra: {
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 80,
          extra: {
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 120,
          extra: {
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '申报数',
          dataIndex: 'upload_num',
          width: 100
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 120,
          extra: {
            disabled: (record) => {
              return !!record.id
            }
          }
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100
        },
        {
          title: '毛重',
          dataIndex: 'gloss_weight',
          width: 100
        },
        {
          title: '净重',
          dataIndex: 'weight',
          width: 100
        },
        {
          title: '库位',
          dataIndex: 'stock_place',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'supple_remark',
          width: 200
        }
      ],
      payTypeMap: {
        0: 'pay_target',
        1: 'driver',
        2: 'vehicle',
        3: 'fleet'
      },
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeOps: [],
      payFeeOps: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select style="width: 100%" value={text}
                showSearch
                disabled={record.disabled}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = 0
                  for (const item of this.chargeFeeOps) {
                    if (item.value === val) {
                      value = item.attribution_fee
                    }
                  }
                  this.chargeFeeData[index]['fee_name_type'] = val
                  this.chargeFeeData[index]['fee_class'] = value
                }}>
                  {
                    this.chargeFeeOps.map(op => {
                        return (<a-select-option key={op.value}>{op.name}</a-select-option>)
                    })
                  }
              </a-select>
            )
          }
        },
        {
          title: '费用类型',
          dataIndex: 'fee_class',
          width: 150,
          extra: {
            type: 'select',
            options: [{ value: 0, name: '仓储包干收入' }, { value: 1, name: '报关报检收入' }, { value: 2, name: '代理运输收入' }],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 1,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          width: 100,
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            defaultValue: 19,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_num',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预收',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预收', value: 1 },
              { name: '预收', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      payFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_type',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select style="width: 100%" value={text}
                showSearch
                disabled={record.disabled}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = 0
                  for (const item of this.payFeeOps) {
                    if (item.value === val) {
                      value = item.attribution_fee
                    }
                  }
                  this.payFeeData[index]['fee_name_type'] = val
                  this.payFeeData[index]['fee_class'] = value
                }}>
                  {
                    this.payFeeOps.map(op => {
                        return (<a-select-option key={op.value}>{op.name}</a-select-option>)
                    })
                  }
              </a-select>
            )
          }
        },
        {
          title: '费用类型',
          dataIndex: 'fee_class',
          width: 150,
          extra: {
            type: 'select',
            options: [{ value: 0, name: '仓储包干收入' }, { value: 1, name: '报关报检收入' }, { value: 2, name: '代理运输收入' }],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '类型',
          dataIndex: 'fee_type',
          extra: {
            defaultValue: 2,
            hidden: true,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'money',
          extra: {
            extraFunc: this.calcTotalPay,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '币种',
          dataIndex: 'currency',
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['currency_type'],
            options: [],
            defaultValue: 19,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款对象',
          dataIndex: 'pay_target_id',
          extra: {
            type: 'select',
            func: getSupplier,
            params: [ { department: 7 } ],
            // flexible: true,
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          extra: {
            type: 'select',
            options: [
              { name: '非预付', value: 1 },
              { name: '预付', value: 2 }
            ],
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      totalChargeFee: 0,
      totalPayFee: 0,
      payQrcodeVisible: false,
      getQrcodeParams: {}
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'ExportCUR') { // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create') { // 新增
          this.isEdit = false
          this.$route.meta.title = '新增出库业务'
          this.sourceForm.id = null
          this.form.id = null
        } else {
          this.disabled = newRoute.query.disabled
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.disabled = this.$route.query.disabled === true
      this.getEditData(this.$route.params.id)
    }
  },
  created() {
    this.handleFormReset()
    this.getFormBasicData()
  },
  methods: {
    queryClearDetail() {
      if (this.$route.params.id === 'create') {
        if (this.form.clear_sys_serial_num) {
          this.customerInfo = undefined
          this.goodsData = []
          this.form['auth_agency'] = null
          this.form.export_date = null
          this.form.box_data = null
          this.form.operate_remark = null
          detailBySerialNum({ 'serial_num': this.form.clear_sys_serial_num }).then(v => {
            if (v.order) {
              this.customerInfo = {
                key: v.order.auth_agency,
                label: v.order.agency_name
              }
              this.form.clear_serial_num = v.order.clear_serial_num
              this.form['auth_agency'] = v.order.auth_agency
              this.form.export_date = v.order.export_date
              this.form.box_data = v.order.box_data
              this.form.operate_remark = v.order.remark
              v.goods.forEach(item => {
                this.goodsData.push({
                    pgood_id: item.id,
                    check_num: item.check_num,
                    good_name: item.good_name,
                    num: item.stock_num,
                    good_spec: item.good_spec,
                    good_material: item.good_material,
                    unit: item.unit,
                    upload_unit: item.upload_unit,
                    upload_num: item.upload_num - item.out_upload_num,
                    legal_unit: item.legal_unit,
                    legal_num: item.legal_num - item.out_legal_num,
                    gloss_weight: item.gloss_weight - item.out_gloss_weight,
                    weight: item.weight - item.out_weight,
                    stock_place: item.stock_place,
                    supple_remark: item.supple_remark
                })
              })
            }
          })
        }
      }
    },
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    getCommonOptions,
    getCumsPayQrcode,
    moment,
    filterOption,
    handleFormReset() {
      this.form = {}
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      this.activeKey = '1'
      this.goodsData = []
      this.chargeFeeData = []
      this.payFeeData = []
      this.customerInfo = undefined
      this.totalChargeFee = 0
      this.totalPayFee = 0
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    getEditData(id) {
      this.isEdit = true
      this.spinning = true
      this.$route.meta.title = '编辑出库业务'
      this.activeKey = '1'
      getCumsBusinsessDetail('cums_export', { id: id }).then(v => {
        const record = v.order
        // record['sail_date'] = record['sail_date'] ? moment(record['sail_date'], 'YYYY/MM/DD') : null
        record['export_date'] = record['export_date'] ? moment(record['export_date'], 'YYYY/MM/DD') : null
        record['check_date'] = record['check_date'] ? moment(record['check_date'], 'YYYY/MM/DD') : null
        record['send_date'] = record['send_date'] ? moment(record['send_date'], 'YYYY/MM/DD') : null
        record['dispatch_date'] = record['dispatch_date'] ? moment(record['dispatch_date'], 'YYYY/MM/DD') : null
        this.form = {
          ...record,
          box_data: record.box_data || this.sourceForm.box_data
        }
        this.customerInfo = {
          key: v.order.auth_agency,
          label: v.order.agency_name
        }
        this.goodsData = v.goods
        v.charge_fees.forEach(fee => {
          if (typeof fee.fee_class === 'undefined') {
            fee.fee_class = null
          }
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        v.pay_fees.forEach(fee => {
          if (typeof fee.fee_class === 'undefined') {
            fee.fee_class = null
          }
          if ((fee.fee_status > 2 && fee.fee_status !== 4) || (fee.is_balance === 2 && fee.fee_status !== 4) || (fee.is_payment === 2 && fee.fee_status !== 4)) fee.disabled = true
        })
        this.chargeFeeData = v.charge_fees
        this.payFeeData = v.pay_fees
        this.chargeFeeData.forEach(v => {
          this.totalChargeFee += parseFloat(v.money)
        })
        this.payFeeData.forEach(v => {
          this.totalPayFee += parseFloat(v.money)
        })
        // v.charge_fees.forEach(fee => {
        //   if (fee.fee_status > 2 && fee.fee_status !== 4) fee.disabled = true;
        // });
        // v.pay_fees.forEach(fee => {
        //   if (fee.fee_status > 2 && fee.fee_status !== 4) fee.disabled = true;
        // });
        this.handleAgencyChange()
        this.spinning = false
      }).catch(_ => {
        this.spinning = false
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agencyOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agencyOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['auth_agency'] = value.key
      this.agencyOps = []
      this.fetching = false
      getNumOptions('cums_clear', { auth_agency: value.key }).then(v => {
        this.goodsColumns[0].extra.options = v
        this.goodsColumns = [
          ...this.goodsColumns
        ]
      })
    },
    getFormBasicData() {
      getCommonOptions('box_info').then(v => {
        this.boxOps = v
      })
      getCommonOptions('fee', { department: 7, type: 1 }).then(v => {
        this.chargeFeeOps = v
      })
      getCommonOptions('fee', { department: 7, type: 2 }).then(v => {
        this.payFeeOps = v
      })
      // getCommonOptions('customer').then(v => {
      //   this.agencyOps = v
      // })
      // getCumsMetaOption('cums_meta_info', { type: 1 }).then(v => {
      //   this.portOps = v
      // })
      // getCumsMetaOption('cums_meta_info', { type: 5 }).then(v => {
      //   this.tradeOps = v
      // })
      getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
        this.customerOps = v
      })
    },
    // handleAgencyChange() {
    //   getNumOptions('cums_clear', { auth_agency: this.form.auth_agency }).then(v => {
    //     this.goodsColumns[0].extra.options = v
    //     this.goodsColumns = [
    //       ...this.goodsColumns
    //     ]
    //   })
    // },
    goodNameChange(field, e) {
      const val = e.target.value
      const newData = this.goodsData
      const goodIds = this.goodsData.map(v => {
        return v.pgood_id
      })
      getListClearGoods({
        '%good_name': field === 'good_name' ? val : '',
        '%check_num': field === 'check_num' ? val : ''
      }).then(v => {
        const _data = v.map(item => {
          return {
            pgood_id: item.id,
            check_num: item.check_num,
            good_name: item.good_name,
            num: item.stock_num,
            good_spec: item.good_spec,
            good_material: item.good_material,
            unit: item.unit,
            upload_unit: item.upload_unit,
            upload_num: item.upload_num - item.out_upload_num,
            legal_unit: item.legal_unit,
            legal_num: item.legal_num - item.out_legal_num,
            gloss_weight: item.gloss_weight - item.out_gloss_weight,
            weight: item.weight - item.out_weight,
            stock_place: item.stock_place,
            supple_remark: item.supple_remark
          }
        }).filter(f => {
          return !goodIds.includes(f.pgood_id)
        })
        newData.splice(newData.length - 1, 1);
        this.goodsData = [ ...newData, ..._data ]
      })
    },
    checkNumChange(value, newData, target, index) {
      const goodIds = this.goodsData.map(v => {
        return v.pgood_id
      })
      return getListClearGoods({
        id: value
      }).then(g => {
        const _data = g.map(item => {
          return {
            pgood_id: item.id,
            check_num: item.check_num,
            good_name: item.good_name,
            num: item.stock_num,
            good_spec: item.good_spec,
            good_material: item.good_material,
            unit: item.unit,
            upload_unit: item.upload_unit,
            upload_num: item.upload_num - item.out_upload_num,
            legal_unit: item.legal_unit,
            legal_num: item.legal_num - item.out_legal_num,
            gloss_weight: item.gloss_weight - item.out_gloss_weight,
            weight: item.weight - item.out_weight,
            stock_place: item.stock_place,
            supple_remark: item.supple_remark
          }
        }).filter(f => {
          return !goodIds.includes(f.pgood_id)
        })
        newData.splice(newData.length - 1, 1);
        this.goodsData = [ ...newData, ..._data ]
        return [ ...newData, ..._data ]
      })
    },
    createItem(field) { // 增加更多箱型
      if (field === 'box_data') {
        this.form.box_data.push({ id: null, num: null, box_num: '' })
      } else {
        this.form[field].push('')
      }
    },
    delItem(field, index) {
      this.form[field].splice(index, 1)
    },
    getGoodsData(data) {
      this.goodsData = data
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    payTypeChange(value, newData, target, index) {
      const key = this.payTypeMap[value]
      target['pay_target_id'] = null
      this.$nextTick(_ => {
        const optarget = this.$refs['import_pay_fee'].tableOptions
        this.$set(optarget, 'pay_target_id' + index, [])
        getPayTargetList(key).then(v => {
          this.$set(optarget, 'pay_target_id' + index, v)
        })
      })
      return newData
    },
    calcTotalCharge(value, newData) {
      let money = 0
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalChargeFee = money
      return newData
    },
    calcTotalPay(value, newData) {
      let money = 0
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalPayFee = money
      return newData
    },
    getPayQrcode() {
      this.getQrcodeParams = {
        type: 2,
        id: this.form.id,
        deal_type: 2
      }
      this.payQrcodeVisible = true
    },
    handleExport(type) {
      exportCmsLetter('cums_export', { id: this.form.id || this.$route.params.id }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            order: {
              ...this.form,
              // sail_date: this.form.sail_date ? moment(this.form.sail_date).valueOf() : null,
              export_date: this.form.export_date ? moment(this.form.export_date).valueOf() : null,
              check_date: this.form.check_date ? moment(this.form.check_date).valueOf() : null,
              send_date: this.form.send_date ? moment(this.form.send_date).valueOf() : null,
              dispatch_date: this.form.dispatch_date ? moment(this.form.dispatch_date).valueOf() : null
            },
            goods: this.goodsData.map(a => {
              a.gloss_weight = a.gloss_weight || 0
              a.weight = a.weight || 0
              return a
            }),
            charge_fees: this.chargeFeeData,
            pay_fees: this.payFeeData
          }
          saveCumsBusinessInfo('cums_export', data).then(v => {
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.isEdit = true
              this.sourceForm.id = v
              this.form.id = v
              this.getEditData(this.form.id);
            })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped>
  .field-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    color: red;
  }
  .field-item .field-item_del{
    position: absolute;
    top: -5px;
    left: 230px;
    color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
  .box-item{
    width:310px;
  }
  .box-item:last-child{
    width: 410px;
  }
  .box-item .field-item_del{
    left: 300px;
  }
  .field-item_del:hover{
    color: #1890ff;
  }
</style>
